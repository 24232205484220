import React from "react";

// Human readable title, ie: Wednesday, January 22, 2020, 5:12:01 PM
const titleFormatter = new Intl.DateTimeFormat(
  typeof navigator === "object" ? navigator.language : "en",
  {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "long",
  }
);

// ie: 26 Nov 1975
const dateFormatter = new Intl.DateTimeFormat(
  typeof navigator === "object" ? navigator.language : "en",
  {
    day: "numeric",
    month: "short",
    year: "numeric",
  }
);

// ie: 2:31 PM
const timeFormatter = new Intl.DateTimeFormat(
  typeof navigator === "object" ? navigator.language : "en",
  {
    hour: "numeric",
    minute: "2-digit",
  }
);

// ie: 26 Nov 1975, 2:31 PM
const dateTimeFormatter = new Intl.DateTimeFormat(
  typeof navigator === "object" ? navigator.language : "en",
  {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }
);

// ie: 2:31:01.23 PM
const timePrecisionFormatter = new Intl.DateTimeFormat(
  typeof navigator === "object" ? navigator.language : "en",
  {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 2,
  }
);

type Props = {
  /**
   * The date to format
   */
  date?: string;
  /**
   * The format to use
   * @example
   * "date" // 26 Nov 1975
   * "time" // 2:31 PM
   * "datetime" // 26 Nov 1975, 2:31 PM
   * "time-precision" // 2:31:01.23 PM
   *
   */
  format?: "date" | "time" | "datetime" | "time-precision";
};

export default function DateText(props: Props) {
  if (!props.date) {
    return null;
  }

  const date = new Date(props.date);
  if (isNaN(date.valueOf())) {
    return null;
  }

  const format = props.format || "date";
  const isoDate = date.toISOString();

  const title = titleFormatter.format(date);

  return (
    <time dateTime={isoDate} title={title}>
      {formatDate(format, date)}
    </time>
  );
}

function formatDate(
  format: "date" | "time" | "datetime" | "time-precision",
  date: Date
) {
  switch (format) {
    case "date":
      return dateFormatter.format(date);
    case "time":
      return timeFormatter.format(date);
    case "datetime":
      return dateTimeFormatter.format(date);
    case "time-precision":
      return timePrecisionFormatter.format(date);
  }
}
